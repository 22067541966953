
import { defineComponent } from "vue";
import SubCatalogueGrid from "@/components/controls/catalogueGrid/SubCatalogueGrid.vue";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";

export default defineComponent({
  components: {
    SubCatalogueGrid,
  },
  props: {
    parentModel: { required: true },
  },
  methods: {},
  computed: {},
  data() {
    return {
      columns: [
        new IntegerColumnConfig({
          dataField: "weight",
          caption: "Масса груза (кг)",
          min: 0,
          validationRules: [requiredRule],
        }),
        new IntegerColumnConfig({
          dataField: "longitudinalDisplacement",
          caption: "Смещение (мм)",
          min: 0,
          validationRules: [requiredRule],
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        hoverStateEnabled: false,
      }),
      designConfig: new TableDesignConfig({
        height: "400px",
        pagingMode: "scroll",
        heightScroll: "400px",
        horizontalScroll: false,
      }),
      editingConfig: new TableEditingConfig({
        allowExport: false,
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
        mode: "row",
      }),
    };
  },
});
